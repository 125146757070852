






































































import moment from 'moment';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RequestStatus, HelpTypes } from '@/helpers';

@Component
export default class CareRecipientTable extends Vue {
  @Prop() private items!: [];
  @Prop() private onItemClick!: Function;

  data() {
    return {
      columns: [
        {
          label: '',
          field: 'open',
          filterOptions: {
            enabled: false, // enable filter for this column
          },
          sortable: false,
        },
        {
          label: 'Datum',
          field: 'created',
          type: 'Date',
          filterOptions: {
            enabled: true,
            filterDropdownItems: ['Afgelopen week', 'Afgelopen maand', 'Afgelopen jaar'],
            filterFn: this.dateFilter,
          },
        },
        {
          label: 'Status',
          field: 'status',
          filterOptions: {
            enabled: true, // enable filter for this column
            filterDropdownItems: RequestStatus,
          },
          sortable: false,
        },
        {
          label: 'Soorthulp',
          field: 'helptype',
          filterOptions: {
            enabled: true, // enable filter for this column
            filterDropdownItems: HelpTypes,
          },
          sortable: false,
        },
        {
          label: 'Gekoppeld aan',
          field: 'caregiver.displayName',
          filterOptions: {
            enabled: true, // enable filter for this column
          },
        },
        {
          label: 'Adres',
          field: 'caregiver.address',
          filterOptions: {
            enabled: true, // enable filter for this column
          },
        },
        {
          label: 'Postcode',
          field: 'caregiver.zipcode',
          filterOptions: {
            enabled: true, // enable filter for this column
          },
        },
        {
          label: 'Mobiel',
          field: 'caregiver.mobileNumber',
          filterOptions: {
            enabled: true, // enable filter for this column
          },
        },
        {
          label: 'Aangemaakt door',
          field: 'user.displayName',
          filterOptions: {
            enabled: true, // enable filter for this column
          },
        },
        {
          label: 'Aangepast door',
          field: 'userUpdates.displayName',
          filterOptions: {
            enabled: true, // enable filter for this column
          },
        },
        {
          label: 'Datum gewijzigd',
          field: 'updated',
          type: 'Date',
          filterOptions: {
            enabled: true,
            filterDropdownItems: ['Afgelopen week', 'Afgelopen maand', 'Afgelopen jaar'],
            filterFn: this.dateFilter,
          },
        },
      ],
    };
  }

  dateFilter(data: string, filterString: string) {
    const dt = moment(data);
    if (filterString === 'Afgelopen week') {
      const weekDT = moment().subtract(7, 'days');
      return dt.isSameOrAfter(weekDT);
    }
    if (filterString === 'Afgelopen maand') {
      const weekDT = moment().subtract(1, 'month');
      return dt.isSameOrAfter(weekDT);
    }
    if (filterString === 'Afgelopen jaar') {
      const weekDT = moment().subtract(1, 'year');
      return dt.isSameOrAfter(weekDT);
    }
  }
}
