














































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { RequestStatus, HelpTypes, YesNo, Duration } from '@/helpers';

@Component
export default class CaregiverItem extends Vue {
  @Prop() private caregiverItem!: {};

  data() {
    return {
      status: RequestStatus,
      helptypes: HelpTypes,
      yesNo: YesNo,
      duration: Duration,
    };
  }
}
