var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vue-good-table',{attrs:{"columns":_vm.columns,"pagination-options":{
    enabled: true,
    mode: 'records',
    nextLabel: 'volgende',
    prevLabel: 'vorige',
    rowsPerPageLabel: 'Aantal per pagina',
    ofLabel: 'van',
    pageLabel: 'pagina', // for 'pages' mode
    allLabel: 'Alles',
  },"sort-options":{
    enabled: true,
    initialSortBy: { field: 'created', type: 'desc' },
  },"styleClass":"tableOne vgt-table table-striped table-hover","rows":_vm.items},on:{"on-row-click":_vm.onItemClick},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'helptype')?_c('span',[_c('span',[_vm._v(_vm._s(_vm._f("formatHelpType")(props.formattedRow[props.column.field])))])]):(props.column.field === 'open')?_c('span',[(props.formattedRow['status'] === '1')?_c('b-icon',{attrs:{"icon":"circle-fill","variant":"danger"}}):_vm._e(),(props.formattedRow['status'] === '2')?_c('b-icon',{attrs:{"icon":"circle-fill","variant":"info"}}):_vm._e(),(props.formattedRow['status'] === '3')?_c('b-icon',{attrs:{"icon":"circle-fill","color":"#888"}}):_vm._e(),(props.formattedRow['status'] === '4')?_c('b-icon',{attrs:{"icon":"circle-fill","variant":"success"}}):_vm._e(),(props.formattedRow['status'] === '5')?_c('b-icon',{attrs:{"icon":"circle-fill","variant":"warning"}}):_vm._e()],1):(props.column.field === 'updated')?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(props.row.updated))+" ")]):(props.column.field === 'created')?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(props.row.created))+" ")]):(props.column.field === 'status')?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatRequestStatus")(props.row.status))+" ")]):(props.row.caregiver && props.column.field === 'caregiver.address')?_c('span',[_vm._v(" "+_vm._s(props.row.caregiver.address)+" "+_vm._s(props.row.caregiver.houseNumber)+" ")]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }