



























































































































































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { UserStatus, YesNo, Salutation } from "@/helpers";

@Component
export default class ParticipantItem extends Vue {
  @Prop() private item!: {
    password: string;
  };
  @Prop() private onSave!: Function;

  private passwordLength = 0;
  private containsEightCharacters = false;
  private containsNumber = false;
  private containsUppercase = false;
  private containsSpecialCharacter = false;
  private validPassword = true;

  data() {
    return {
      salutation: Salutation,
      userStatus: UserStatus,
      yesNo: YesNo,
      password: null,
    };
  }

  checkPassword() {
    this.passwordLength = this.item.password.length;
    const format = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

    if (this.passwordLength === 0) {
      this.validPassword = true;

      return;
    }

    if (this.passwordLength > 8) {
      this.containsEightCharacters = true;
    } else {
      this.containsEightCharacters = false;
    }

    this.containsNumber = /\d/.test(this.item.password);
    this.containsUppercase = /[A-Z]/.test(this.item.password);
    this.containsSpecialCharacter = format.test(this.item.password);

    if (
      this.containsEightCharacters === true &&
      this.containsSpecialCharacter === true &&
      this.containsUppercase === true &&
      this.containsNumber === true
    ) {
      this.validPassword = true;
    } else {
      this.validPassword = false;
    }
  }
}
