<template>
  <div class="main-content">
    <b-modal
      id="confirm-remove-participant"
      hide-backdrop
      content-class="shadow"
      title="Verwijderen"
    >
      <template v-slot:modal-footer="{ ok, cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button
          size="sm"
          variant="success"
          @click.prevent="removeParticipant"
        >
          Ja
        </b-button>
        <!-- Button with custom close trigger value -->
        <b-button
          size="sm"
          variant="outline-secondary"
          @click.prevent="cancel()"
        >
          Nee
        </b-button>
      </template>
      <p class="my-4">
        Weet u zeker dat u deze deelnemer wilt verwijderen?
      </p>
    </b-modal>
    <b-modal
      id="confirm-archive-participant"
      hide-backdrop
      content-class="shadow"
      title="Archiveren"
    >
      <template v-slot:modal-footer="{ ok, cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button
          size="sm"
          variant="success"
          @click.prevent="anonymizeParticipant"
        >
          Ja
        </b-button>
        <!-- Button with custom close trigger value -->
        <b-button
          size="sm"
          variant="outline-secondary"
          @click.prevent="cancel()"
        >
          Nee
        </b-button>
      </template>
      <p class="my-4">
        Weet u zeker dat u deze deelnemer wilt archiveren?
      </p>
    </b-modal>
    <div class="card user-profile o-hidden mb-30">
      <div class="header-cover" />
      <b-button-toolbar class="btn-float">
        <button
          v-if="this.participant._id"
          type="button"
          class="btn btn-danger mr-2"
          @click.prevent="showConfirmRemoveParticipant"
        >
          <b-icon icon="trash" />
        </button>
        <button
          v-if="this.participant._id && this.participant.isDeleted !== 'yes'"
          type="button"
          class="btn btn-warning"
          @click.prevent="showConfirmAnonymizeParticipant"
        >
          <b-icon icon="archive" />
        </button>
      </b-button-toolbar>
      <div class="user-info">
        <img
          class="profile-picture avatar-lg mb-2"
          src="@/assets/images/default.png"
          alt=""
        >
        <p class="m-0 text-24">
          {{ participant.displayName }}
        </p>
      </div>
      <b-card class="wrapper">
        <b-tabs
          content-class="mt-3"
          align="center"
        >
          <b-tab
            title="Personalia"
            active
          >
            <participant-item
              :item="participant"
              :on-save="saveParticipant"
            />
          </b-tab>
          <b-tab
            v-if="this.participant.isDeleted !== 'yes'"
            :title="hulpvraagTitle"
            :disabled="!participant._id"
          >
            <div class="btn-bar">
              <button
                type="button"
                class="btn btn-primary ripple m-1"
                @click.prevent="newCareRecipientItem"
              >
                Toevoegen
              </button>
            </div>
            <div class="clearfix" />
            <care-recipient-table
              :items="careRecipientItems"
              :on-item-click="onCareRecipientItemClick"
            />
            <b-modal
              id="care-recipient-item-modal"
              size="xl"
              :ok-title="okLabel"
              :cancel-title="cancelLabel"
              title="Hulpvraag"
              @ok="saveCareRecipientItem"
            >
              <template v-slot:modal-footer="{ ok, cancel }">
                <!-- Emulate built in modal footer ok and cancel button actions -->
                <b-button
                  size="sm"
                  variant="success"
                  @click.prevent="ok()"
                >
                  Opslaan
                </b-button>
                <b-button
                  v-if="careRecipientItem._id"
                  size="sm"
                  variant="danger"
                  @click.prevent="showConfirmRemoveCareRecipientItem"
                >
                  Verwijderen
                </b-button>
                <!-- Button with custom close trigger value -->
                <b-button
                  size="sm"
                  variant="outline-secondary"
                  @click="cancel()"
                >
                  Sluiten
                </b-button>
              </template>
              <b-card>
                <b-modal
                  id="confirm-remove-carerecipient-item"
                  hide-backdrop
                  content-class="shadow"
                  title="Verwijderen"
                >
                  <template v-slot:modal-footer="{ ok, cancel }">
                    <!-- Emulate built in modal footer ok and cancel button actions -->
                    <b-button
                      size="sm"
                      variant="success"
                      @click.prevent="removeCareRecipientItem"
                    >
                      Ja
                    </b-button>
                    <!-- Button with custom close trigger value -->
                    <b-button
                      size="sm"
                      variant="outline-secondary"
                      @click.prevent="cancel()"
                    >
                      Nee
                    </b-button>
                  </template>
                  <p class="my-4">
                    Weet u zeker dat u dit item wilt verwijderen?
                  </p>
                </b-modal>
                <care-recipient-item :care-recipient-item="careRecipientItem" />
              </b-card>
            </b-modal>
          </b-tab>
          <b-tab
            v-if="participant.status === 'verified'"
            :title="hulpaanbodTitle"
            :disabled="!participant._id"
          >
            <div class="btn-bar">
              <button
                type="button"
                class="btn btn-primary ripple m-1"
                @click.prevent="newCaregiverItem"
              >
                Toevoegen
              </button>
            </div>
            <div class="clearfix" />
            <caregiver-table
              :caregiver-items="caregiverItems"
              :on-caregiver-item-click="onCaregiverItemClick"
            />
            <b-modal
              id="caregiver-item-modal"
              size="xl"
              :ok-title="okLabel"
              :cancel-title="cancelLabel"
              title="Hulpaanbod"
              @ok="saveCaregiverItem"
            >
              <template v-slot:modal-footer="{ ok, cancel }">
                <!-- Emulate built in modal footer ok and cancel button actions -->
                <b-button
                  size="sm"
                  variant="success"
                  @click.prevent="ok()"
                >
                  Opslaan
                </b-button>
                <b-button
                  v-if="caregiverItem._id"
                  size="sm"
                  variant="danger"
                  @click.prevent="showConfirmRemoveCaregiverItem"
                >
                  Verwijderen
                </b-button>
                <!-- Button with custom close trigger value -->
                <b-button
                  size="sm"
                  variant="outline-secondary"
                  @click.prevent="cancel()"
                >
                  Sluiten
                </b-button>
              </template>
              <b-card>
                <b-modal
                  id="confirm-remove-caregiver-item"
                  hide-backdrop
                  content-class="shadow"
                  title="Verwijderen"
                >
                  <template v-slot:modal-footer="{ ok, cancel }">
                    <!-- Emulate built in modal footer ok and cancel button actions -->
                    <b-button
                      size="sm"
                      variant="success"
                      @click.prevent="removeCaregiverItem"
                    >
                      Ja
                    </b-button>
                    <!-- Button with custom close trigger value -->
                    <b-button
                      size="sm"
                      variant="outline-secondary"
                      @click.prevent="cancel()"
                    >
                      Nee
                    </b-button>
                  </template>
                  <p class="my-4">
                    Weet u zeker dat u dit item wilt verwijderen?
                  </p>
                </b-modal>
                <caregiver-item :caregiver-item="caregiverItem" />
              </b-card>
            </b-modal>
          </b-tab>
          <b-tab
            :title="matchesTitle"
            :disabled="!participant._id"
          >
            <div class="clearfix" />
            <matches-table :items="matchItems" />
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>
<script>
import { participantsService } from "@/services";
import CaregiverTable from "@/components/caregiver-table.vue";
import CaregiverItem from "@/components/caregiver-item.vue";
import CareRecipientTable from "@/components/carerecipient-table.vue";
import CareRecipientItem from "@/components/carerecipient-item.vue";
import ParticipantItem from "@/components/participant-item.vue";
import MatchesTable from "@/components/matches-table.vue";

export default {
  components: {
    CaregiverTable,
    CaregiverItem,
    CareRecipientTable,
    CareRecipientItem,
    ParticipantItem,
    MatchesTable,
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Deelnemers",
  },
  data() {
    return {
      participant: {},
      caregiverItems: [],
      caregiverItem: {},
      careRecipientItems: [],
      careRecipientItem: {},
      okLabel: "Opslaan",
      cancelLabel: "Sluiten",
      matchItems: [],
    };
  },
  computed: {
    hulpvraagTitle() {
      return `Hulpvraag (${
        this.careRecipientItems ? this.careRecipientItems.length : 0
      })`;
    },
    hulpaanbodTitle() {
      return `Hulpaanbod (${
        this.caregiverItems ? this.caregiverItems.length : 0
      })`;
    },
    matchesTitle() {
      return `Matches (${this.matchItems ? this.matchItems.length : 0})`;
    },
  },
  created() {
    if (this.$route.params.id === "_new") {
      this.participant = {};
      this.participant.salutation = "none";
      this.participant.isNew = true;
    } else {
      participantsService.getById(this.$route.params.id).then((participant) => {
        this.participant = participant;
        this.participant.registration = new Date(
          this.participant.registration ||
            this.participant.created ||
            new Date()
        );
        this.participant.salutation = this.participant.salutation || "none";
        participantsService
          .getCaregiverItems(this.participant)
          .then((caregiverItems) => {
            this.caregiverItems = caregiverItems;
          });
        participantsService
          .getCareRecipientsItems(this.participant)
          .then((careRecipientItems) => {
            this.careRecipientItems = careRecipientItems;
          });
        participantsService
          .getMatchItems(this.participant)
          .then((matchItems) => {
            console.log(matchItems);
            this.matchItems = matchItems;
          });
      });
    }
  },
  methods: {
    saveParticipant() {
      participantsService
        .save(this.participant)
        .then((res) => {
          if (this.participant.isNew) {
            this.$router.push(`/participants/${res._id}`);
          }
          this.participant = res;
          this.$bvToast.toast("Opgeslagen", {
            title: "Melding",
            autoHideDelay: 2500,
            appendToast: false,
            variant: "info",
          });
        })
        .catch((err) => {
          console.error(err);
          this.$bvToast.toast(err, {
            title: "Foutmelding",
            autoHideDelay: 2500,
            appendToast: false,
            variant: "danger",
          });
        });
    },
    showConfirmRemoveParticipant() {
      this.$bvModal.show("confirm-remove-participant");
    },
    removeParticipant() {
      participantsService
        .remove(this.participant._id)
        .then((res) => {
          this.$bvModal.hide("confirm-remove-participant");
          this.$bvToast.toast("Verwijderd", {
            title: "Melding",
            autoHideDelay: 2500,
            appendToast: false,
            variant: "info",
          });
          this.$router.push("/home");
        })
        .catch((err) => {
          console.error(err);
          this.$bvToast.toast(err, {
            title: "Foutmelding",
            autoHideDelay: 2500,
            appendToast: false,
            variant: "danger",
          });
        });
    },
    showConfirmAnonymizeParticipant() {
      this.$bvModal.show("confirm-archive-participant");
    },
    anonymizeParticipant() {
      participantsService
        .anonymize(this.participant._id)
        .then((res) => {
          this.$bvModal.hide("confirm-archive-participant");
          this.$bvToast.toast("Gearchiveerd", {
            title: "Melding",
            autoHideDelay: 2500,
            appendToast: false,
            variant: "info",
          });
          this.$router.push("/home");
        })
        .catch((err) => {
          console.error(err);
          this.$bvToast.toast(err, {
            title: "Foutmelding",
            autoHideDelay: 2500,
            appendToast: false,
            variant: "danger",
          });
        });
    },
    saveCaregiverItem() {
      this.caregiverItem.helptype = [this.caregiverItem.helptypeAlt];
      participantsService
        .saveCaregiverItem(this.caregiverItem)
        .then((res) => {
          console.debug(res);
          this.caregiverItem = {};
          participantsService
            .getCaregiverItems(this.participant)
            .then((caregiverItems) => {
              this.caregiverItems = caregiverItems;
            });
          this.$bvToast.toast("Opgeslagen", {
            title: "Melding",
            autoHideDelay: 2500,
            appendToast: false,
            variant: "info",
          });
        })
        .catch((err) => {
          console.error(err);
          this.$bvToast.toast(err, {
            title: "Foutmelding",
            autoHideDelay: 2500,
            appendToast: false,
            variant: "danger",
          });
        });
    },
    closeCaregiverItem() {
      this.caregiverItem = {};
    },
    newCaregiverItem() {
      this.caregiverItem = {};
      this.caregiverItem.isNew = true;
      this.caregiverItem.person = this.participant._id;
      this.$bvModal.show("caregiver-item-modal");
    },
    saveCareRecipientItem() {
      participantsService
        .saveCareRecipientItem(this.careRecipientItem)
        .then((res) => {
          console.debug(res);
          this.careRecipientItem = {};
          participantsService
            .getCareRecipientsItems(this.participant)
            .then((careRecipientItems) => {
              this.careRecipientItems = careRecipientItems;
            });
          participantsService
            .getMatchItems(this.participant)
            .then((matchItems) => {
              this.matchItems = matchItems;
            });
          this.$bvToast.toast("Opgeslagen", {
            title: "Melding",
            autoHideDelay: 2500,
            appendToast: false,
            variant: "info",
          });
        })
        .catch((err) => {
          console.error(err);
          this.$bvToast.toast(err, {
            title: "Foutmelding",
            autoHideDelay: 2500,
            appendToast: false,
            variant: "danger",
          });
        });
    },
    closeCareRecipientItem() {
      this.careRecipientItem = {};
    },
    showConfirmRemoveCaregiverItem() {
      this.$bvModal.show("confirm-remove-caregiver-item");
    },
    removeCaregiverItem() {
      participantsService
        .removeCaregiverItem(this.caregiverItem)
        .then((res) => {
          participantsService
            .getCaregiverItems(this.participant)
            .then((caregiverItems) => {
              this.caregiverItems = caregiverItems;
            });
          this.$bvModal.hide("confirm-remove-caregiver-item");
          this.$bvModal.hide("caregiver-item-modal");
          this.$bvToast.toast("Verwijderd", {
            title: "Melding",
            autoHideDelay: 2500,
            appendToast: false,
            variant: "info",
          });
        })
        .catch((err) => {
          console.error(err);
          this.$bvToast.toast(err, {
            title: "Foutmelding",
            autoHideDelay: 2500,
            appendToast: false,
            variant: "danger",
          });
        });
    },

    onCaregiverItemClick(data) {
      this.caregiverItem = JSON.parse(JSON.stringify(data.row));
      this.caregiverItem.helptypeAlt = this.caregiverItem.helptype[0];
      console.log(this.caregiverItem);
      this.$bvModal.show("caregiver-item-modal");
    },

    /* CareRecipient */
    newCareRecipientItem() {
      this.careRecipientItem = {};
      this.careRecipientItem.isNew = true;
      this.careRecipientItem.person = this.participant;
      this.$bvModal.show("care-recipient-item-modal");
    },
    onCareRecipientItemClick(data) {
      this.careRecipientItem = JSON.parse(JSON.stringify(data.row));
      this.$bvModal.show("care-recipient-item-modal");
    },
    showConfirmRemoveCareRecipientItem() {
      this.$bvModal.show("confirm-remove-carerecipient-item");
    },
    removeCareRecipientItem() {
      participantsService
        .removeCareRecipientItem(this.careRecipientItem)
        .then((res) => {
          participantsService
            .getCareRecipientsItems(this.participant)
            .then((careRecipientItems) => {
              this.careRecipientItems = careRecipientItems;
            });
          this.$bvModal.hide("confirm-remove-carerecipient-item");
          this.$bvModal.hide("care-recipient-item-modal");
          this.$bvToast.toast("Verwijderd", {
            title: "Melding",
            autoHideDelay: 2500,
            appendToast: false,
            variant: "info",
          });
        })
        .catch((err) => {
          console.error(err);
          this.$bvToast.toast(err, {
            title: "Foutmelding",
            autoHideDelay: 2500,
            appendToast: false,
            variant: "danger",
          });
        });
    },
  },
};
</script>
<style></style>
